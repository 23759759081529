<template>
  <div class="relative w-full flex justify-center bg-yellow-400 px-4 pt-1 pb-6">
    <div
      class="absolute left-4 top-1/2 -translate-y-1/2 cursor-pointer"
      @click="goBack"
    >
      <div
        class="relative inline-block w-8 h-8 rounded-full border-[2px] border-red-700"
      >
        <font-awesome-icon icon="fa-chevron-left" class="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-lg text-red-700" />
      </div>
    </div>
    <p class="font-semibold mt-2 mb-1">{{ name }}</p>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

library.add(faChevronLeft)

export default {
  name: "Navbar",
  props: ["name", "backroute"],
  components: {
    FontAwesomeIcon,
  },
  methods: {
    goBack() {
      console.log(this.backroute)
      this.$router.push({name: this.backroute});
    },
  },
};
</script>
