<template>
  <div
    @click="logout"
    v-if="!notShownRoutes.includes($route.name)"
    class="absolute bottom-0 text-lg z-20 w-full h-14 px-4 flex justify-center items-center bg-yellow-400 text-gray-700 cursor-pointer"
  >
    Logout
  </div>
</template>

<script>
import makeHttpRequest from "@/js/makeHttpRequest";
export default {
  data() {
    return {
      notShownRoutes: ["login", "transferHistory", "transaction", "transfer", "receiverTransaction", "editTransaction"],
    };
  },
  methods: {
    async logout() {
      try {
        const url = this.$store.getters.getLogoutUrl;
        const token = this.$store.getters.getToken;
        console.log(token);
        const data = await makeHttpRequest(url, token, "GET", "");
        if (data.success) {
          this.$store.commit("updateToken", "");
          console.log(this.$store.getters.getToken);
          this.$router.push({ name: "login" });
        } else {
          console.log(data);
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
</script>
