import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import LoginPage from "../views/LoginPage";
import HomePage from "../views/HomePage";
import TransferPage from "@/components/TransferPage.vue";
// import TransferHistory from "@/components/TransferHistory.vue";
// import Transaction from "@/components/Transaction.vue";
// import ReceiveHistory from "@/components/ReceiveHistory.vue";
import ReceiveTransaction from "@/components/ReceiveTransaction.vue";
import ScreenshotTransaction from "@/components/ScreenshotTransaction.vue";
import EditTransaction from '@/components/EditTransferPage.vue';
import AgentIndex from "@/components/AgentIndex.vue";
import AgentCreate from "@/components/AgentCreate.vue";
import AgentEdit from "@/components/AgentEdit.vue";
import TransactionIndex from "@/components/TransactionIndex.vue";
import TransactionShow from "@/components/TransactionShow.vue";


const routes = [
  {
    path: "/",
    name: "login",
    component: LoginPage,
    beforeEnter(to, from, next) {
      const token = store.getters.getToken;
      const isAuthenticated = token;
      if (isAuthenticated) next({ ...to });
      else next();
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/transfer",
    name: "transfer",
    component: TransferPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/agent/index",
    name: "agentIndex",
    component: AgentIndex,
    meta: { requiresAuth: true },
  },
  {
    path: "/agent/create",
    name: "agentCreate",
    component: AgentCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/agent/edit/",
    name: "agentEdit",
    component: AgentEdit,
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/receive/history",
  //   name: "receiveHistory",
  //   component: ReceiveHistory,
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/transaction",
    name: "transaction",
    component: TransactionIndex,
    meta: { requiresAuth: true },
  },
  {
    path: "/transaction/create",
    name: "createTransaction",
    component: TransferPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/transaction/show",
    name: "showTransaction",
    component: TransactionShow,
    meta: { requiresAuth: true },
  },
  {
    path: "/receiver/transaction",
    name: "receiverTransaction",
    component: ScreenshotTransaction,
    meta: { requiresAuth: true },
  },
  {
    path: "/receive/transaction",
    name: "receiveTransaction",
    component: ReceiveTransaction,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = store.getters.getToken;
    const isAuthenticated = token;

    if (!isAuthenticated) {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
