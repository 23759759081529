<template>
    <Navbar name="Agents" backroute="home" />
    <div class="relative bg-white rounded-2xl -mt-3 p-4 z-10">
      <div class="flex justify-end pb-3 border-b-[1px] border-gray-300">
        <!-- <input
          type="date"
          class="text-gray-300 focus:border-none focus:outline-none"
          v-model="date"
        /> -->
        <input type="text" 
        v-model="search" 
        class="border-gray-200 border w-full py-2 px-2 rounded-s" 
        placeholder="Search">
        <button @click="getAgents" class="px-4 bg-yellow-400 hover:bg-yellow-500 rounded-e">
          <font-awesome-icon icon="fa-search"/>
        </button>
      </div>
      <div class="w-100 text-right">
        <router-link :to="{name: 'agentCreate'}" class="rounded-md bg-yellow-400 inline-block p-2 mt-2">Register</router-link>
      </div>
      <!-- <ul class="h-[82vh] overflow-y-scroll"> -->
        
        <!-- <div class="flex justify-between py-4 border-b-[1px] border-gray-300 px-2"
          v-for="agent in agents"
          :key="item">
          <div class="flex items-center gap-4">
            <font-awesome-icon icon="fa-dollar-sign" class="block text-xl text-yellow-400" />
            <div class="flex flex-col items-start">
              <p class="text-base font-bold text-gray-800">
                {{ item.note }}
              </p>
              <p class="text-sm text-gray-500 font-semibold">
                {{ formatDate(item.created_at) }}
              </p>
            </div>
          </div>
          <div class="flex flex-col justify-center items-end">
            <h2 class="text-green-700 font-bold" v-if="item.action=='added'">
              {{ '+ '+item.amount }} {{ item.currencyCode ?? "RM" }}
            </h2>
            <h2 class="text-red-700 font-bold" v-else>
              {{ '- '+item.amount }} {{ item.currencyCode ?? "RM" }}
            </h2>
          </div>
          </div> -->
      <!-- </ul> -->

      <div class="min-w-full mt-2">
        <div class="overflow-x-auto">
          <table class="table-auto bg-gray-100 text-surface">
            <thead class="border-b">
              <tr>
                <th scope="col" class="px-3 py-2">#</th>
                <th scope="col" class="px-3 py-2">Name</th>
                <th scope="col" class="px-3 py-2">Phone</th>
                <th scope="col" class="px-3 py-2">Password</th>
                <th scope="col" class="px-3 py-2" style="min-width: 200px !important">Created Date</th>
                <th scope="col" class="px-3 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-b" v-for="(agent, index) in agents" :key="agent.id">
                <td scope="col" class="px-3 py-2">{{ index + 1 }}</td>
                <td scope="col" class="px-3 py-2">{{ agent.name }}</td>
                <td scope="col" class="px-3 py-2">{{agent.phone}}</td>
                <td scope="col" class="px-3 py-2">{{agent.password}}</td>
                <td scope="col" class="px-3 py-2">{{formatDate(agent.created_at)}}</td>
                <td scope="col" class="px-3 py-2">
                  <div class="flex justify-between px-3">
                  <router-link
                  :to="{
                    name: 'agentEdit',
                    query: { agent: encodeURIComponent(JSON.stringify(agent)) },
                  }"
                  class="flex justify-between items-center">
                    <span class="text-lg mr-2 text-gray-500">
                      <font-awesome-icon icon="fa-pen-to-square" />
                    </span>
                  </router-link>
                  
                  <button @click="deleteAgent(agent.id)" class="text-lg pt-1 text-red-700">
                    <font-awesome-icon icon="fa-trash-can" />
                  </button>
                </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Navbar from "./Navbar";
  import dayjs from "dayjs";
  import axios from "axios";
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faDollarSign, faSearch, faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons'
  
  library.add(faDollarSign, faSearch ,faPenToSquare, faTrashCan)
  
  export default {
    name: "TransferHistory",
    components: { Navbar, FontAwesomeIcon },
    data() {
      return {
        agents: [],
        // agentTransactions: [],
        selectedDate: null,
        date: null,
        search: '',
        token: '',
      };
    },
    mounted() {
      this.token = this.$store.getters.getToken;
      this.getAgents()
    },
    methods: {
      getAgents() {
        try {
          const url = this.$store.getters.getAgentUrl + '/index';
          axios.get(url, {
              params: {search: this.search},
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.token}`,
              }
            })
            .then(resp => {
              console.log(resp)
              this.agents = resp.data.data
            })
            .catch(err => console.log(err))
          } catch (err) {console.log(err.message)}
      },
      deleteAgent(id) {
        if(confirm('Do you want to delete it?')) {
          try {
          const url = this.$store.getters.getAgentUrl + '/delete/' + id;
          axios.delete(url, {
              // params: {date: this.date},
              headers: {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.token}`,
              }
            })
            .then(resp => {
              // console.log(resp)
              // this.agents = resp.data.data
              this.$toast.error('<span class="p-1 text-lg"><i class="fa-solid fa-circle-xmark"></i></span>' + resp.data.message)
              this.getAgents()
            })
            .catch(err => console.log(err))
          } catch (err) {console.log(err.message)}
        }
      },
      formatDate(date) {
        return dayjs(date).format('DD/MM/YY h:m:s A');
      },
    },
    watch: {
      date(value) {
        this.value = value;
        // console.log(value)
        this.getAgents()
      }
    }
  };
  </script>
  
  <style scoped>
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(0.5);
  }
  </style>
  