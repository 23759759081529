<template>
    <Navbar name="Transaction Details" backroute="transaction"/>
    <div
      class="relative bg-white rounded-2xl -mt-3 p-4 pb-20 z-10 h-full overflow-y-scroll"
    >
      <div class="grid grid-cols-2 gap-1 border-b py-2">
        <div class="font-semibold">Voucher Id</div>
        <div class="text-end">{{voucher_id}}</div>
      </div>
      <div class="grid grid-cols-2 gap-1 border-b py-2">
        <div class="font-semibold">Sender Name</div>
        <div class="text-end">{{sender_name}}</div>
      </div>
      <div class="grid grid-cols-2 gap-1 border-b py-2">
        <div class="font-semibold">Sender Phone</div>
        <div class="text-end">{{sender_phone}}</div>
      </div>
      <div class="grid grid-cols-2 gap-1 border-b py-2">
        <div class="font-semibold">Receiver Name</div>
        <div class="text-end">{{receiver_name}}</div>
      </div>
      <div class="grid grid-cols-2 gap-1 border-b py-2">
        <div class="font-semibold">Receiver Phone</div>
        <div class="text-end">{{receiver_phone}}</div>
      </div>
      <div class="grid grid-cols-2 gap-1 border-b py-2">
        <div class="font-semibold">Receiver Phone</div>
        <div class="text-end">{{receiver_national_id}}</div>
      </div>
      <div class="grid grid-cols-2 gap-1 border-b py-2">
        <div class="font-semibold">Amount</div>
        <div class="text-end">{{amount + " MMK"}}</div>
      </div>
      <div class="grid grid-cols-2 gap-1 border-b py-2">
        <div class="font-semibold">Transfer Fee</div>
        <div class="text-end">{{transfer_fee + " RM"}}</div>
      </div>
      <div class="grid grid-cols-2 gap-1 border-b py-2">
        <div class="font-semibold">Exchange Rate</div>
        <div class="text-end">{{"1 RM -> " + exchange_rate + " MMK"}}</div>
      </div>
      <div class="grid grid-cols-2 gap-1 border-b py-2">
        <div class="font-semibold">Created Date</div>
        <div class="text-end">{{formatDate(created_at)}}</div>
      </div>
    </div>
  </template>
  
  <script>
  import Navbar from "./Navbar";
  import dayjs from "dayjs";

  export default {
    components: { Navbar },
    name: "TransferPage",
    data() {
      return {
        voucher_id: "",
        sender_name: "",
        sender_phone: "",
        receiver_name: "",
        receiver_phone: "",
        amount: "",
        transfer_fee: "",
        exchange_rate: "",
        receiver_national_id: "",
        payment_type_id: "",
        created_at: ""
      };
    },
    methods: {
        formatDate(date) {
            return dayjs(date).format('DD/MM/YY h:m:s A');
        },
    },
    mounted() {
        const data = JSON.parse(
            decodeURIComponent(this.$route.query.transaction)
            );
            this.voucher_id = data.voucher_id
            this.sender_name = data.sender_name
            this.sender_phone = data.sender_phone
            this.receiver_name = data.receiver_name
            this.receiver_phone = data.receiver_phone
            this.receiver_national_id = data.receiver_national_id
            this.amount = data.amount
            this.payment_type = data.payment_type
            this.transfer_fee = data.transfer_fee
            this.exchange_rate = data.exchange_rate
            this.created_at = data.created_at
        // this.sender_name = 
    },
  };
  </script>
  