import { createStore } from "vuex";

const BaseUrl = "https://shwehninsi.smartcodemm.com/api/";

export default createStore({
  state: {
    loginUrl: `${BaseUrl}admin/login`,
    logoutUrl: `${BaseUrl}logout`,
    agentUrl: `${BaseUrl}agent`,
    createTransactionUrl: `${BaseUrl}transfer-agent/transaction/create`,
    transactionsUrl: `${BaseUrl}transfer-agent/transactions`,
    partialTransactionsUrl: `${BaseUrl}transfer-agent/transactions/partial-completed`,
    pendingTransactionsUrl: `${BaseUrl}transfer-agent/transactions/pending`,
    completedTransactionsUrl: `${BaseUrl}transfer-agent/transactions/completed`,
    incorrectTransactionsUrl: `${BaseUrl}transfer-agent/transactions/incorrect`,
    cancelledTransactionsUrl: `${BaseUrl}transfer-agent/transactions/cancelled`,
    paymentTypesUrl: `${BaseUrl}transfer-agent/payment-type`,
    adminDetailUrl: `${BaseUrl}admin/details`,
    // receiverTransactionsUrl: `${BaseUrl}admin/transactions`,
    adminTransactionsUrl: `${BaseUrl}admin/transaction`,
    totalAmountUrl: `${BaseUrl}transfer-agent/transaction/total-amount`,
    token: "",
    transactions: [],
    admin: [],
  },
  getters: {
    getLoginUrl: (state) => state.loginUrl,
    getLogoutUrl: (state) => state.logoutUrl,
    getAgentUrl: (state) => state.agentUrl,
    getCreateTransactionUrl: (state) => state.createTransactionUrl,
    getTransactionsUrl: (state) => state.transactionsUrl,
    getPartialTransactionsUrl: (state) => state.partialTransactionsUrl,
    getPendingTransactionsUrl: (state) => state.pendingTransactionsUrl,
    getCompletedTransactionsUrl: (state) => state.completedTransactionsUrl,
    getIncorrectTransactionsUrl: (state) => state.incorrectTransactionsUrl,
    getCancelledTransactionsUrl: (state) => state.cancelledTransactionsUrl,
    getAdminTransactionsUrl: (state) => state.adminTransactionsUrl,
    getPaymentTypesUrl: (state) => state.paymentTypesUrl,
    getAdminDetailUrl: (state) => state.adminDetailUrl,
    getReceiverTransactionsUrl: (state) => state.receiverTransactionsUrl,
    getToken: (state) => state.token,
    getTransactions: (state) => state.transactions,
    getAgent: (state) => state.agent,
    getTotalAmountUrl: (state) => state.totalAmountUrl,
  },
  mutations: {
    updateToken(state, value) {
      state.token = value;
    },
    updateTransactions(state, value) {
      state.transactions.push(value);
    },
    updateAdmin(state, value) {
      state.admin = value;
    },
  },
  actions: {},
  modules: {},
});
