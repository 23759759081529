<template>
  <Navbar name="Create Transaction" backroute="transaction"/>
  <div
    class="relative bg-white rounded-2xl -mt-3 p-4 pb-20 z-10 h-full overflow-y-scroll"
  >
    <form class="flex flex-col gap-4" @submit.prevent="handleSubmit">
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1">Sender Name</label>
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="text"
          placeholder="Enter sender name"
          autofocus
          v-model="formData.sender_name"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1">Sender Phone</label>
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="text"
          placeholder="Enter sender phone"
          autofocus
          v-model="formData.sender_phone"
        />
      </div>
      <!-- <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1"
          >Sender National ID</label
        >
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="text"
          placeholder="Enter sender national ID"
          autofocus
          v-model="formData.sender_national_id"
        />
      </div> -->
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1">Receiver Name</label>
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="text"
          placeholder="Enter receiver name"
          autofocus
          v-model="formData.receiver_name"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1">Receiver Phone</label>
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="text"
          placeholder="Enter receiver phone"
          autofocus
          v-model="formData.receiver_phone"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1"
          >Receiver National ID</label>
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="text"
          placeholder="Enter receiver national ID"
          v-model="formData.receiver_national_id"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1"
          >Select Payment Method</label>
        <select
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          v-model="formData.payment_type_id"
        >
          <option value="" selected disabled>Choose your method</option>
          <option
            :value="payment.id"
            v-for="payment in paymentMethods"
            :key="payment.id"
          >
            {{ payment.name }}
          </option>
        </select>
      </div>
      <!-- <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1"
          >Select Receive Type</label>
        <select
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          v-model="formData.receive_type">
          <option value="" disabled>Choose your receive type</option>
          <option
            value="cash"
            selected
            v-if="$store.getters.getAgent.type === 'agent'">
            Cash
          </option>
          <option
            value="bank"
            selected
            v-if="$store.getters.getAgent.type === 'admin'">
            Bank
          </option>
        </select>
      </div> -->
      <!-- <hr class="text-gray-700 mb-2" /> -->
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1">Amount (MMK)</label>
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="number"
          @change="showTotalAmount"
          placeholder="Enter amount"
          v-model="formData.amount"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1">Transfer Fee (RM)</label>
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="number"
          placeholder="Enter transfer fee"
          v-model="formData.transfer_fee"
        />
      </div>
      <!-- <div v-if="totalAmount!=null">
        <label for="" class="block font-semibold ml-1">Total Amount (RM)</label>
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="text"
          v-model="totalAmount" 
          disabled
        />
      </div> -->
      <div class="flex flex-col gap-2 mb-16">
        <button type="submit" @click="isLoading=true" class="block w-full p-2 rounded-lg bg-yellow-400 cursor-pointer" :class="{'bg-yellow-300':isLoading}">
          <div role="status">
            <svg v-show="isLoading" aria-hidden="true" class="inline w-5 h-5 text-gray-100 animate-spin dark:text-gray-400 fill-gray-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span class="sr-only">Loading...</span>
            <span class="ms-2" :class="{'text-gray-500':isLoading}">Submit</span>
          </div>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Navbar from "./Navbar";
import makeHttpRequest from "@/js/makeHttpRequest";
export default {
  components: { Navbar },
  name: "TransferPage",
  data() {
    return {
      token: "",
      transactions: [],
      paymentMethods: [],
      formData: {
        sender_name: "",
        sender_phone: "",
        receiver_name: "",
        receiver_phone: "",
        amount: "",
        transfer_fee: "",
        // sender_national_id: "",
        receiver_national_id: "",
        payment_type_id: "",
       // receive_type: "",
      },
      // totalAmount: null,
      isLoading: false,
    };
  },
  methods: {
    async getPaymentTypes() {
      const url = this.$store.getters.getPaymentTypesUrl;
      try {
        const data = await makeHttpRequest(url, this.token);
        if (data.success) {
          this.paymentMethods = data.data;
        } else {
          console.log(data);
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    async handleSubmit() {
      console.log(this.formData, this.token);
      const url = this.$store.getters.getAdminTransactionsUrl + "/store";
      try {
        const data = await makeHttpRequest(
          url,
          this.token,
          "POST",
          this.formData
        );
        if (data.success) {
          this.isLoading = false;
          // this.formData.date = new Date();
          this.$store.commit("updateTransactions", this.formData);
          // console.log(this.$store.state.transactions);
          this.$router.push({ name: "transaction" });
        } else {
          this.isLoading = false;
          this.$toast.error('<span class="p-1 text-lg"><i class="fa-solid fa-circle-xmark"></i></span>' + data.message)
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    // async showTotalAmount(e) {
    //   const amount = e.target.value;
    //   const formData = {
    //     'amount': amount,
    //     'payment_type_id': this.formData.payment_type_id
    //   }
    //   console.log(formData)
    //   const url = this.$store.getters.getTotalAmountUrl;
    //   try {
    //     const data = await makeHttpRequest(
    //       url, 
    //       this.token,
    //       "POST", 
    //       formData
    //     );
    //     if (data.success) {
    //       console.log(data)
    //       this.totalAmount = data.data
    //     } else {
    //       console.log(data);
    //     }
    //   } catch (error) {
    //     console.log(error.message);
    //   }
    // },
  },
  mounted() {
    this.token = this.$store.getters.getToken;
    this.getPaymentTypes();
  },
};
</script>
